// SPDX-FileCopyrightText: 2017-2022 City of Espoo
//
// SPDX-License-Identifier: LGPL-2.1-or-later

import React from 'react'

import {
  daycareAssistanceLevels,
  preschoolAssistanceLevels
} from 'lib-common/generated/api-types/assistance'
import type { EmployeeCustomizations } from 'lib-customizations/types'

import { employeeConfig } from './appConfigs'
import { H3, P } from 'lib-components/typography'
import MunicipalityLogo from './assets/municipality-logo-primary.svg'
import featureFlags from './featureFlags'

const customizations: EmployeeCustomizations = {
  appConfig: employeeConfig,
  translations: {
    fi: {
      common: {
        retroactiveConfirmation: {
          checkboxLabel:
            'Ymmärrän, olen asiasta yhteydessä laskutustiimiin ka.varhaiskasvatus@kajaani.fi *'
        }
      },
      footer: {
        cityLabel: 'Kajaani kaupunki',
        linkLabel: 'Kajaanin varhaiskasvatus',
        linkHref: 'https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatus/'
      },
      unitEditor: {
        placeholder: {
          email: 'etunimi.sukunimi@kajaani.fi',
          url: 'esim. https://www.kajaani.fi/fi/toimipisteet/15585',
        }
      },
      welcomePage: {
        text: 'Olet kirjautunut sisään Kajaanin kaupungin eVaka-palveluun. Käyttäjätunnuksellesi ei ole vielä annettu oikeuksia, jotka mahdollistavat palvelun käytön. Tarvittavat käyttöoikeudet saat omalta esimieheltäsi.'
      },
      preferredFirstName: {
        description:
      'Voit määritellä eVakassa käytössä olevan kutsumanimesi. Kutsumanimen tulee olla jokin etunimistäsi. Jos nimesi on vaihtunut ja sinulla on tarve päivittää eVakaan uusi nimesi, ole yhteydessä Kajaanin tukeen.',
      },
      login: {
        loginAD: 'Kajaanin AD',
      },
      childInformation: {
        assistanceNeedPreschoolDecision: {
          jurisdictionText:
            'Toimivalta: Kajaanin kaupungin hallintosääntö § 28, varhaiskasvatuksen palvelupäällikön toimivalta.',
          appealInstructions: (
            <>
              <P>
                Tähän päätökseen tyytymätön voi tehdä kirjallisen oikaisuvaatimuksen. Päätökseen ei saa hakea muutosta valittamalla tuomioistuimeen.
              </P>
              <H3>Oikaisuvaatimusoikeus</H3>
              <P>
                Oikaisuvaatimuksen saa tehdä se, johon päätös on kohdistettu tai
                jonka oikeuteen, velvollisuuteen tai etuun päätös välittömästi
                vaikuttaa (asianosainen).
              </P>
              <H3>Oikaisuvaatimusaika</H3>
              <P>
                Oikaisuvaatimus on tehtävä 14 päivän kuluessa päätöksen
                tiedoksisaannista.
              </P>
              <P>
                Oikaisuvaatimus on toimitettava Pohjois-Suomen aluehallintovirastolle
                viimeistään määräajan viimeisenä päivänä ennen aluehallintoviraston
                aukioloajan päättymistä.
              </P>
              <P>
                Asianosaisen katsotaan saaneen päätöksestä tiedon, jollei muuta näytetä,
                seitsemän päivän kuluttua kirjeen lähettämisestä.
              </P>
              <P>
                Käytettäessä tavallista sähköistä tiedoksiantoa asianosaisen
                katsotaan saaneen päätöksestä tiedon, jollei muuta näytetä,
                kolmantena päivänä viestin lähettämisestä.
              </P>
              <P>
                Tiedoksisaantipäivää ei lueta oikaisuvaatimusaikaan. Jos
                oikaisuvaatimusajan viimeinen päivä on pyhäpäivä, itsenäisyyspäivä,
                vapunpäivä, joulu- tai juhannusaatto tai arkilauantai, saa
                oikaisuvaatimuksen tehdä ensimmäisenä arkipäivänä sen jälkeen.
              </P>
              <H3>Oikaisuviranomainen</H3>
              <P>
                Oikaisuvaatimus tehdään Pohjois-Suomen aluehallintovirastolle
              </P>
              <P>
                Postiosoite: PL 6, 13035 AVI
                <br />
                Käyntiosoite: Linnankatu 3, Oulu
                <br />
                Sähköpostiosoite: kirjaamo.pohjois@avi.fi
                <br />
                Puhelinvaihde: 0295 016 000
                <br />
                Kirjaamon aukioloaika: klo 8.00–16.15
              </P>
              <H3>Oikaisuvaatimuksen muoto ja sisältö</H3>
              <P>
                Oikaisuvaatimus on tehtävä kirjallisesti. Myös sähköinen asiakirja
                täyttää vaatimuksen kirjallisesta muodosta.
              </P>
              <P noMargin>Oikaisuvaatimuksessa, joka on osoitettava valitusviranomaiselle, on ilmoitettava:</P>
              <ul>
                <li>päätös, johon vaaditaan oikaisua,</li>
                <li>miltä kohdin päätökseen haetaan oikaisua ja millaista oikaisua siihen vaaditaan tehtäväksi</li>
                <li>millä perusteella oikaisua vaaditaan.</li>
              </ul>
              <P>
                Oikaisuvaatimuksessa on ilmoitettava tekijän nimi, postiosoite,
                puhelinnumero ja muut asian hoitamiseksi tarvittavat yhteystiedot.
              </P>
              <P>
                Jos oikaisuvaatimuksen tekijän puhevaltaa käyttää hänen laillinen edustajansa tai asiamiehensä tai
                jos oikaisuvaatimuksen laatijana on joku muu henkilö, oikaisuvaatimuksessa on ilmoitettava
                myös tämän yhteystiedot.
              </P>
              <P>
                Jos oikaisuvaatimusviranomaisen päätös voidaan antaa tiedoksi sähköisenä viestinä,
                yhteystietona pyydetään ilmoittamaan myös sähköpostiosoite.
              </P>
              <P>
                Oikaisuvaatimuksen tekijän, laillisen edustajan tai asiamiehen on allekirjoitettava oikaisuvaatimus.
                Sähköistä asiakirjaa ei kuitenkaan tarvitse täydentää allekirjoituksella, jos asiakirjassa on tiedot
                lähettäjästä eikä asiakirjan alkuperäisyyttä tai eheyttä ole syytä epäillä.
              </P>
              <P noMargin>Oikaisuvaatimukseen on liitettävä</P>
              <ul>
                <li>
                  päätös, johon haetaan oikaisua, alkuperäisenä tai jäljennöksenä oikaisuvaatimusohjeineen
                </li>
                <li>
                  todistus siitä, minä päivänä päätös on annettu tiedoksi, tai muu
                  selvitys oikaisuvaatimusajan alkamisen ajankohdasta
                </li>
                <li>
                  asiakirjat, joihin oikaisuvaatimuksen tekijä vetoaa, jollei
                  niitä ole jo aikaisemmin toimitettu viranomaiselle.
                </li>
              </ul>
            </>
          ),
        },
        assistanceNeedDecision: {
          jurisdictionText:
            'Toimivalta: Kajaanin kaupungin hallintosääntö § 28, varhaiskasvatuksen palvelupäällikön toimivalta.',
          appealInstructions: (
            <>
            <P>
              Tähän päätökseen tyytymätön voi tehdä kirjallisen oikaisuvaatimuksen. Päätökseen ei saa hakea muutosta valittamalla tuomioistuimeen.
            </P>
            <H3>Oikaisuvaatimusoikeus</H3>
            <P>
              Oikaisuvaatimuksen saa tehdä se, johon päätös on kohdistettu tai
              jonka oikeuteen, velvollisuuteen tai etuun päätös välittömästi
              vaikuttaa (asianosainen).
            </P>
            <H3>Oikaisuvaatimusaika</H3>
            <P>
              Oikaisuvaatimus on tehtävä 14 päivän kuluessa päätöksen
              tiedoksisaannista.
            </P>
            <P>
              Oikaisuvaatimus on toimitettava Pohjois-Suomen aluehallintovirastolle
              viimeistään määräajan viimeisenä päivänä ennen aluehallintoviraston
              aukioloajan päättymistä.
            </P>
            <P>
              Asianosaisen katsotaan saaneen päätöksestä tiedon, jollei muuta näytetä,
              seitsemän päivän kuluttua kirjeen lähettämisestä.
            </P>
            <P>
              Käytettäessä tavallista sähköistä tiedoksiantoa asianosaisen
              katsotaan saaneen päätöksestä tiedon, jollei muuta näytetä,
              kolmantena päivänä viestin lähettämisestä.
            </P>
            <P>
              Tiedoksisaantipäivää ei lueta oikaisuvaatimusaikaan. Jos
              oikaisuvaatimusajan viimeinen päivä on pyhäpäivä, itsenäisyyspäivä,
              vapunpäivä, joulu- tai juhannusaatto tai arkilauantai, saa
              oikaisuvaatimuksen tehdä ensimmäisenä arkipäivänä sen jälkeen.
            </P>
            <H3>Oikaisuviranomainen</H3>
            <P>
              Oikaisuvaatimus tehdään Pohjois-Suomen aluehallintovirastolle
            </P>
            <P>
              Postiosoite: PL 6, 13035 AVI
              <br />
              Käyntiosoite: Linnankatu 3, Oulu
              <br />
              Sähköpostiosoite: kirjaamo.pohjois@avi.fi
              <br />
              Puhelinvaihde: 0295 016 000
              <br />
              Kirjaamon aukioloaika: klo 8.00–16.15
            </P>
            <H3>Oikaisuvaatimuksen muoto ja sisältö</H3>
            <P>
              Oikaisuvaatimus on tehtävä kirjallisesti. Myös sähköinen asiakirja
              täyttää vaatimuksen kirjallisesta muodosta.
            </P>
            <P noMargin>Oikaisuvaatimuksessa, joka on osoitettava valitusviranomaiselle, on ilmoitettava:</P>
            <ul>
              <li>päätös, johon vaaditaan oikaisua,</li>
              <li>miltä kohdin päätökseen haetaan oikaisua ja millaista oikaisua siihen vaaditaan tehtäväksi</li>
              <li>millä perusteella oikaisua vaaditaan.</li>
            </ul>
            <P>
              Oikaisuvaatimuksessa on ilmoitettava tekijän nimi, postiosoite,
              puhelinnumero ja muut asian hoitamiseksi tarvittavat yhteystiedot.
            </P>
            <P>
              Jos oikaisuvaatimuksen tekijän puhevaltaa käyttää hänen laillinen edustajansa tai asiamiehensä tai
              jos oikaisuvaatimuksen laatijana on joku muu henkilö, oikaisuvaatimuksessa on ilmoitettava
              myös tämän yhteystiedot.
            </P>
            <P>
              Jos oikaisuvaatimusviranomaisen päätös voidaan antaa tiedoksi sähköisenä viestinä,
              yhteystietona pyydetään ilmoittamaan myös sähköpostiosoite.
            </P>
            <P>
              Oikaisuvaatimuksen tekijän, laillisen edustajan tai asiamiehen on allekirjoitettava oikaisuvaatimus.
              Sähköistä asiakirjaa ei kuitenkaan tarvitse täydentää allekirjoituksella, jos asiakirjassa on tiedot
              lähettäjästä eikä asiakirjan alkuperäisyyttä tai eheyttä ole syytä epäillä.
            </P>
            <P noMargin>Oikaisuvaatimukseen on liitettävä</P>
            <ul>
              <li>
                päätös, johon haetaan oikaisua, alkuperäisenä tai jäljennöksenä oikaisuvaatimusohjeineen
              </li>
              <li>
                todistus siitä, minä päivänä päätös on annettu tiedoksi, tai muu
                selvitys oikaisuvaatimusajan alkamisen ajankohdasta
              </li>
              <li>
                asiakirjat, joihin oikaisuvaatimuksen tekijä vetoaa, jollei
                niitä ole jo aikaisemmin toimitettu viranomaiselle.
              </li>
            </ul>
          </>
          )
        }
      },
    },
    sv: {},
  },
  vasuTranslations: {
    FI: {},
    SV: {},
  },
  cityLogo: <img src={MunicipalityLogo} alt="Kajaani logo" data-qa="footer-city-logo" />,
  featureFlags,
  placementTypes: [
    'PRESCHOOL',
    'PRESCHOOL_DAYCARE',
    'DAYCARE',
    'DAYCARE_PART_TIME',
    'PREPARATORY',
    'PREPARATORY_DAYCARE',
    'CLUB',
    'TEMPORARY_DAYCARE',
    'TEMPORARY_DAYCARE_PART_DAY',
  ],
  absenceTypes: [
    'OTHER_ABSENCE',
    'SICKLEAVE',
    'UNKNOWN_ABSENCE',
    'PLANNED_ABSENCE',
    'PARENTLEAVE',
    'FORCE_MAJEURE',
  ],
  daycareAssistanceLevels: [...daycareAssistanceLevels],
  otherAssistanceMeasureTypes: [
    'TRANSPORT_BENEFIT',
    'ACCULTURATION_SUPPORT',
    'ANOMALOUS_EDUCATION_START',
  ],
  placementPlanRejectReasons: ['REASON_1', 'REASON_2', 'OTHER'],
  preschoolAssistanceLevels: [...preschoolAssistanceLevels],
  unitProviderTypes: [
    'MUNICIPAL',
    'PURCHASED',
    'PRIVATE',
    'MUNICIPAL_SCHOOL',
    'PRIVATE_SERVICE_VOUCHER',
    'EXTERNAL_PURCHASED',
  ],
  voucherValueDecisionTypes: [
    'NORMAL',
    'RELIEF_ACCEPTED',
    'RELIEF_PARTLY_ACCEPTED',
    'RELIEF_REJECTED',
  ],
}

export default customizations
