
import { MapConfig } from 'lib-customizations/types'

const mapConfig: MapConfig = {
  center: [64.22203, 27.72791],
  initialZoom: 13,
  addressZoom: 14,
  searchAreaRect: {
    maxLatitude: 64.52229136595398,
    minLatitude: 64.02229136595398,
    maxLongitude: 27.99834101544383,
    minLongitude: 27.22834101544383,
  },
  careTypeFilters: ['DAYCARE', 'PRESCHOOL', 'CLUB'],
  unitProviderTypeFilters: [
    'MUNICIPAL',
    'PURCHASED',
    'PRIVATE',
    'PRIVATE_SERVICE_VOUCHER',
  ],
}
export default mapConfig
