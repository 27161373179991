
import { BaseAppConfig } from 'lib-customizations/types'

import { env, Env } from './env'

type AppConfigs = {
  default: BaseAppConfig
} & {
  [k in Env]: BaseAppConfig
}

const employeeConfigs: AppConfigs = {
  default: {
    sentry: {
      dsn: 'https://21a3609b1aa44b5894a6edc384fae3c7@sentry.haltu.net/36',
      enabled: true,
    },
  },
  staging: {
    sentry: {
      dsn: 'https://21a3609b1aa44b5894a6edc384fae3c7@sentry.haltu.net/36',
      enabled: true,
    },
  },
  prod: {
    sentry: {
      dsn: 'https://21a3609b1aa44b5894a6edc384fae3c7@sentry.haltu.net/36',
      enabled: true,
    },
  },
}

const employeeMobileConfigs: AppConfigs = {
  default: {
    sentry: {
      dsn: 'https://21a3609b1aa44b5894a6edc384fae3c7@sentry.haltu.net/36',
      enabled: true,
    },
  },
  staging: {
    sentry: {
      dsn: 'https://21a3609b1aa44b5894a6edc384fae3c7@sentry.haltu.net/36',
      enabled: true,
    },
  },
  prod: {
    sentry: {
      dsn: 'https://21a3609b1aa44b5894a6edc384fae3c7@sentry.haltu.net/36',
      enabled: true,
    },
  },
}

const citizenConfigs: AppConfigs = {
  default: {
    sentry: {
      dsn: 'https://21a3609b1aa44b5894a6edc384fae3c7@sentry.haltu.net/36',
      enabled: true,
    },
  },
  staging: {
    sentry: {
      dsn: 'https://21a3609b1aa44b5894a6edc384fae3c7@sentry.haltu.net/36',
      enabled: true,
    },
  },
  prod: {
    sentry: {
      dsn: 'https://21a3609b1aa44b5894a6edc384fae3c7@sentry.haltu.net/36',
      enabled: true,
    },
  },
}

const employeeConfig = employeeConfigs[env()]
const employeeMobileConfig = employeeMobileConfigs[env()]
const citizenConfig = citizenConfigs[env()]

export { employeeConfig, employeeMobileConfig, citizenConfig }
