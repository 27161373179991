
import React from 'react'
// import styled from 'styled-components'

// import { FixedSpaceColumn } from 'lib-components/layout/flex-helpers'
import type { CitizenCustomizations} from 'lib-customizations/types'

import { citizenConfig } from './appConfigs'
import MunicipalityLogo from './assets/municipality-logo-primary.svg'
import featureFlags from './featureFlags'
import mapConfig from './mapConfig'
import { H1, H2, H3, P } from 'lib-components/typography'
import ExternalLink from 'lib-components/atoms/ExternalLink'
import colors from 'lib-customizations/common'

// const MultiLineCheckboxLabel = styled(FixedSpaceColumn).attrs({
//   spacing: 'zero'
// })`
//   margin-top: -6px;
// `

const customizations: CitizenCustomizations = {
  appConfig: citizenConfig,
  langs: ['fi', 'sv', 'en'],
  translations: {
    fi: {
      header: {
        lang: {
          fi: 'Suomeksi',
          sv: '',
          en: 'In English'
        },
        langMobile: {
          fi: 'Suomeksi',
          sv: '',
          en: 'English'
        },
      },
      footer: {
        cityLabel: '© Kajaanin kaupunki',
        privacyPolicyLink: (
          <a
            href="https://www.kajaani.fi/kaupunki-ja-hallinto/yhteystiedot-ja-asiointi/tietosuoja/"
            data-qa="footer-policy-link"
            style={{ color: colors.main.m2 }}
          >
            Tietosuojaselosteet
          </a>
        ),
        sendFeedbackLink: (
          <a
            href="https://asiointi.kajaani.fi/eFeedback"
            data-qa="footer-feedback-link"
            style={{ color: colors.main.m2 }}
          >
            Lähetä palautetta
          </a>
        ),
      },
      loginPage: {
        title: 'Kajaanin kaupungin varhaiskasvatus',
        login: {
          infoBoxText: (
            <>
            <P>
              Mikäli kirjautuminen ei onnistu, voit olla yhteydessä sähköpostiosoitteeseen{' '}
              <a href="mailto:ka.varhaiskasvatus@kajaani.fi">ka.varhaiskasvatus@kajaani.fi</a>
              . Voit kirjautua myös käyttämällä vahvaa tunnistautumista.
          </P>
            </>
          ),
        },
      },
      map: {
        mainInfo:
          "Tässä näkymässä voit hakea kartalta Kajaanin varhaiskasvatus-, esiopetus- ja kerhopaikkoja.",
        serviceVoucherLink:
          'https://kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/yksityinen-varhaiskasvatus/varhaiskasvatuksen-palveluseteli/',
        privateUnitInfo: (
          <span>
            Yksityinen varhaiskasvatus | Kajaani{' '}
            <ExternalLink
              text="täältä."
              href="https://kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/yksityinen-varhaiskasvatus/"
              newTab
            />
          </span>
        ),
      },
      applications: {
        creation: {
          daycareInfo:
            'Varhaiskasvatushakemuksella haetaan kunnallista varhaiskasvatuspaikkaa päiväkotiin. Samalla hakemuksella voi hakea myös varhaiskasvatuksen palveluseteliä yksityiseen päiväkotiin ja perhepäivähoitoon.',
          preschoolInfo: (
            <>
              <P>
                Esiopetus ( 4h/päivä ) on maksutonta, mutta sen ylittävältä ajalta peritään varhaiskasvatuksen asiakasmaksu,
                joka määräytyy perheen tulojen, koon ja lapsen varhaiskasvatuksen ajan mukaan.{' '}
                <a href='https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/esiopetus/'>
                  https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/esiopetus/</a>
              </P>
            </>
          ) as unknown as string,
          transferApplicationInfo: {
            DAYCARE:
              'Lapsella on jo paikka Kajaanin varhaiskasvatuksessa. Tällä hakemuksella voit hakea siirtoa toiseen varhaiskasvatusta tarjoavaan yksikköön Kajaanissa.',
          },
          applicationInfo: (
            <P>
              Hakemukseen voi tehdä muutoksia niin kauan kuin hakemusta ei ole
              otettu käsittelyyn. Tämän jälkeen muutokset hakemukseen tehdään
              ottamalla yhteyttä varhaiskasvatuksen palvelupäällikköön {' '}
              <a href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatukseen-esiopetukseen-hakeminen/">
              (https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatukseen-esiopetukseen-hakeminen/)
              </a>
              .
            </P>
          )
        },
        editor: {
          unitPreference: {
            units: {
              info: {
                DAYCARE: (
                  <>
                    <P>
                      Voit hakea 1-3 paikkaa toivomassasi järjestyksessä.
                      Hakutoiveet eivät takaa paikkaa toivotussa yksikössä, mutta
                      mahdollisuus toivotun paikan saamiseen kasvaa antamalla
                      useamman vaihtoehdon.
                    </P>
                    <P>
                      Näet eri varhaiskasvatusyksiköiden sijainnin valitsemalla
                      ‘Yksiköt kartalla’.
                    </P>
                    <P>
                      Jos haluat yksityiseen varhaiskasvatukseen, ota itse yhteys kyseiseen yksikköön täyttääksesi palvelusopimuksen.
                      Palvelusopimus yksityisen palveluntuottajan kanssa tehdään kyseisessä yksikössä.
                      Palveluseteliä haetaan valitsemalla hakutoiveeksi se palveluseteliyksikkö, johon halutaan hakea.
                      Palveluseteli myönnetään sen jälkeen, kun päiväkodin tai perhepäivähoitajan kanssa tehty palvelusetelisopimus
                      on saapunut varhaiskasvatuksen hallintoon.
                    </P>
                  </>
                ),
                PRESCHOOL: (
                  <>
                    <P>
                      Voit hakea 1-3 eri yksikköön toivomassasi järjestyksessä.
                      Hakutoiveet eivät takaa paikkaa toivotusta yksiköstä, mutta
                      mahdollisuus toivotun paikan saamiseen kasvaa antamalla
                      useamman vaitoehdon.
                    </P>
                    <P>
                      Näet eri yksiköiden sijainnin valitsemalla ‘Yksiköt kartalla’.
                    </P>
                    <P>
                      Jos haluat yksityiseen varhaiskasvatukseen, ota itse yhteys kyseiseen yksikköön täyttääksesi palvelusopimuksen.
                      Palvelusopimus yksityisen palveluntuottajan kanssa tehdään kyseisessä yksikössä.
                      Palveluseteliä haetaan valitsemalla hakutoiveeksi se palveluseteliyksikkö, johon halutaan hakea.
                      Palveluseteli myönnetään sen jälkeen, kun päiväkodin tai perhepäivähoitajan kanssa tehty palvelusetelisopimus
                      on saapunut varhaiskasvatuksen hallintoon.
                    </P>
                  </>
                ),
              },
            },
          },
          heading: {
            info: {
              DAYCARE: (
                <>
                  <P>
                    Varhaiskasvatusta voi hakea ympäri vuoden. Hakemus on jätettävä
                    viimeistään neljä kuukautta ennen kuin tarvitsette paikan.
                    Mikäli tarvitsette varhaiskasvatusta kiireellisesti työn tai
                    opiskelujen vuoksi, tulee paikkaa hakea viimeistään kaksi
                    viikkoa ennen.
                  </P>
                  <P>
                    Saatte kirjallisen päätöksen varhaiskasvatuspaikasta{' '}
                    <a
                      href="https://www.suomi.fi/viestit"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Suomi.fi-viestit
                    </a>{' '}
                    -palveluun tai postitse, mikäli et ole ottanut Suomi.fi
                    -palvelua käyttöön.
                  </P>
                  <P>
                    Kunnallisen varhaiskasvatuksen asiakasmaksut ja palvelusetelin
                    omavastuuosuus määräytyvät prosenttiosuutena perheen
                    bruttotuloista. Tulojen lisäksi maksuihin vaikuttaa perheen koko
                    ja sovittu varhaiskasvatusaika. Palveluseteliyksiköissä voidaan
                    periä lisämaksu, tiedot mahdollisesta lisämaksusta löytyy
                    yksikön nettisivuilta. Perhe toimittaa tuloselvityksen
                    bruttotuloistaan viimeistään kahden viikon kuluessa siitä, kun
                    lapsi on aloittanut varhaiskasvatuksessa.
                  </P>
                  <P>
                    Lisätietoa varhaiskasvatuksen maksuista, tuloselvityksen
                    toimittamisesta ja palvelusetelin lisämaksusta löydät täältä:{' '}
                    <a href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-asiakasmaksut/">
                      Maksut varhaiskasvatuksessa
                    </a>
                    {' '}ja{' '}
                    <a href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/yksityinen-varhaiskasvatus/varhaiskasvatuksen-palveluseteli/">
                      lisätietoa palvelusetelistä
                    </a>
                    .
                  </P>
                  <P fitted={true}>* Tähdellä merkityt tiedot ovat pakollisia</P>
                </>
              ),
              PRESCHOOL: (
                <>
                  <P>
                  Esiopetukseen osallistutaan oppivelvollisuuden alkamista edeltävänä vuonna.
                  Esiopetus on maksutonta. Lisätietoja:{' '}
                  <a href=' https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/esiopetus/'>https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/esiopetus/</a>
                  </P>
                  <P>
                    Päätökset tulevat{' '}
                    <a
                      href="https://www.suomi.fi/viestit"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Suomi.fi-viestit
                    </a>{' '}
                    -palveluun tai postitse, mikäli et ole ottanut Suomi.fi
                    -palvelua käyttöön.
                  </P>
                  <P fitted={true}>* Tähdellä merkityt tiedot ovat pakollisia</P>
                </>
              ),
              CLUB: (
                <>
                  <P>
                    Leikkikerhotoimintaan haetaan sähköisesti varhaiskasvatushakemuksella.
                    Leikkikerhohakemukseen valitaan hoitomuodoksi osapäivähoito ja lisätietoihin kirjoitetaan,
                    että haetaan leikkikerhotoimintaan.
                  </P>
                  <P>
                    Leikkikerhomaksu määräytyy kuten varhaiskasvatuksen asiakasmaksu.
                    Leikkikerhomaksu on 17% kokoaikaisesta varhaiskasvatusmaksusta.
                  </P>
                  <P>
                    Leikkikerhojen toimintakausi on syyskuun alusta toukokuun loppuun.
                    Lapsi voi aloittaa kerhossa aikaisintaan sen kuukauden aikana, jolloin hän täyttää kolme vuotta.
                  </P>
                  <P fitted={true}>* Tähdellä merkityt tiedot ovat pakollisia</P>
                </>
              )
            },
          },
          additionalDetails: {
            dietInfo: (
              <>
                Erityisruokavalioista tarvitaan erikseen lääkärintodistus,
                joka toimitetaan varhaiskasvatuspaikkaan. Poikkeuksena
                vähälaktoosinen tai laktoositon ruokavalio, uskonnollisiin syihin
                perustuva ruokavalio tai kasvisruokavalio (lakto-ovo).
              </>
            ),
          },
          actions: {
            allowOtherGuardianAccess: (
              <span>
                Ymmärrän, että tieto hakemuksesta menee myös lapsen toiselle
                huoltajalle. Jos tieto ei saa mennä toiselle huoltajalle, ole
                yhteydessä palveluohjaukseen. Palveluohjauksen yhteystiedot löydät{' '}
                <a
                  href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatukseen-esiopetukseen-hakeminen/"
                  target="_blank"
                  rel="noreferrer"
                >
                  täältä
                </a>
                .
              </span>
            ),
          },
          serviceNeed: {
            partTime: {
              true: 'Osa-aikainen (max 5h / pv, 25h / vko)',
              false: 'Kokoaikainen'
            },
            shiftCare: {
              attachmentsMessage: {
                text: 'Ilta- ja vuorohoito on tarkoitettu lapsille, joiden molemmat vanhemmat ovat vuorotyössä tai opiskelevat pääsääntöisesti iltaisin ja/tai viikonloppuisin. Hakemuksen liitteeksi toimitetaan molempien vanhempien osalta työnantajan todistus vuorotyöstä tai opiskelusta johtuvasta ilta- tai vuorohoidon tarpeesta. Jos et voi lisätä liitteitä hakemukselle sähköisesti, lähetä ne postilla osoitteeseen Pohjolankatu 13, 87100 Kajaani.',
              },
            },
            startDate: {
              info: {
                PRESCHOOL: [
                  'https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/esiopetus/, Esiopetusta täydentävään varhaiskasvatukseen lasketaan ainoastaan täydentävän varhaiskasvatuksen aika eli 4h päivässä olevaa esiopetusta ei tarvitse laskea palveluntarpeeseen. Eli mikäli lapsenne on varhaiskasvatuksessa ja esiopetuksessa klo 8-16 kuukauden jokaisena arkipäivänä, palveluntarve on tällöin 4h (täydentävä varhaiskasvatus) x 21 = 84h',
                ],
              },
            },
            urgent: {
              attachmentsMessage: {
                text: (
                  <>
                    <P fitted={true}>
                      Mikäli varhaiskasvatuspaikan tarve johtuu äkillisestä
                      työllistymisestä tai opiskelusta, tulee paikkaa hakea
                      viimeistään <strong>kaksi viikkoa ennen</strong> kuin tarve
                      alkaa. Hakemuksen{' '}
                      <strong>liitteenä tulee olla työ- tai opiskelutodistus</strong>{' '}
                      molemmilta samassa taloudessa asuvilta huoltajilta.
                      Suosittelemme toimittamaan liitteen sähköisesti tässä, sillä
                      kahden viikon käsittelyaika alkaa siitä, kun olemme
                      vastaanottaneet hakemuksen tarvittavine liitteineen. Jos et voi
                      lisätä liitteitä hakemukselle sähköisesti,
                      lähetä ne turvasähköpostilla{' '}
                      <a href="https://www.kajaani.fi/kaupunki-ja-hallinto/yhteystiedot-ja-asiointi/turvaposti/">https://www.kajaani.fi/kaupunki-ja-hallinto/yhteystiedot-ja-asiointi/turvaposti/</a>
                      {' '}tai Kajaanin kaupunki/Varhaiskasvatus, Pohjolankatu 13, 87100 Kajaani.
                    </P>
                    <P>
                      <strong>Palveluntarve tarkoittaa kuukaudessa käytettävää varhaiskasvatuksen tuntimäärää. Ilmoita haluttu varhaiskasvatuksen palvelutarve "Muut lisätiedot" -kohdassa ja valitse siihen jokin seuraavista:</strong>:
                      <ul>
                        <li>osa-aikainen, enintään 84 h/kk</li>
                        <li>osa-aikainen, enintään 107 h/kk</li>
                        <li>kokoaikainen, enintään 147 h/kk</li>
                        <li>kokoaikainen, enintään 250 h/kk</li>
                      </ul>
                    </P>
                  </>
                ),
              }
            },
            dailyTime: {
              connectedDaycareInfo: (
                <>
                  <P>
                    Voit hakea lapselle tarvittaessa{' '}
                    <strong>
                      esiopetukseen lisäksi täydentävää varhaiskasvatusta, joka on
                      maksullista.
                    </strong>{' '}
                    Täydentävää varhaiskasvatusta järjestetään aamuisin ennen esiopetusta
                    ja esiopetuksen jälkeen päiväkodin aukioloaikojen mukaisesti.
                  </P>
                  <P>
                    Saat varhaiskasvatuspaikasta erillisen kirjallisen päätöksen.
                    Päätös tulee{' '}
                    <a
                      href="https://www.suomi.fi/viestit"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Suomi.fi-viestit
                    </a>{' '}
                    -palveluun tai postitse, mikäli et ole ottanut Suomi.fi-viestit
                    -palvelua käyttöön.
                  </P>
                </>
              ),
            },
          },
          fee: {
            links: (
              <>
              <P>
                Lisätietoa varhaiskasvatuksen maksuista, palvelusetelin lisämaksusta
                ja tuloselvityslomakkeen löydät täältä:
                <br />
                <a
                  href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-asiakasmaksut/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Maksut varhaiskasvatuksessa
                </a>
              </P>
              <P>
                <a
                  href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/yksityinen-varhaiskasvatus/varhaiskasvatuksen-palveluseteli/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lisätietoa palvelusetelistä
                </a>
              </P>
              </>
            )
          },
          contactInfo: {
            futureAddressInfo:
              "Kajaanin varhaiskasvatuksessa virallisena osoitteena pidetään väestötiedoista saatavaa osoitetta. Osoite väestötiedoissa muuttuu hakijan tehdessä muuttoilmoituksen postiin tai maistraattiin.",
          },
        },
      },
      decisions: {
        assistancePreschoolDecisions: {
          appealInstructions: (
            <>
              <P>
                Tähän päätökseen tyytymätön voi tehdä kirjallisen
                oikaisuvaatimuksen. Päätökseen ei saa hakea muutosta valittamalla
                tuomioistuimeen.
              </P>

              <H3>Oikaisuvaatimusoikeus</H3>
              <P>
                Oikaisuvaatimuksen saa tehdä se, johon päätös on kohdistettu tai
                jonka oikeuteen, velvollisuuteen tai etuun päätös välittömästi
                vaikuttaa (asianosainen).
              </P>

              <H3>Oikaisuvaatimusaika</H3>
              <P>
                Oikaisuvaatimus on tehtävä 30 päivän kuluessa päätöksen
                tiedoksisaannista.
              </P>
              <P>
                Oikaisuvaatimus on toimitettava Kajaanin kunnan
                kirjaamoon määräajan viimeisenä päivänä ennen kirjaamon aukioloajan
                päättymistä.
              </P>
              <P>
                Asianosaisen katsotaan saaneen päätöksestä tiedon, jollei
                muuta näytetä, seitsemän (7) päivän kuluttua kirjeen lähettämisestä.
              </P>
              <P>
                Käytettäessä tavallista sähköistä tiedoksiantoa katsotaan asianosaisen
                saaneen tiedon päätöksestä kolmantena päivänä viestin lähettämisestä.
                Tiedoksisaantipäivää ei lueta oikaisuvaatimusaikaan.
              </P>
              <P>
                Jos
                oikaisuvaatimusajan viimeinen päivä on pyhäpäivä, itsenäisyyspäivä,
                vapunpäivä, joulu- tai juhannusaatto tai arkilauantai, saa
                oikaisuvaatimuksen tehdä ensimmäisenä arkipäivänä sen jälkeen.
              </P>

              <H3>Oikaisuviranomainen</H3>
              <P>
                Viranomainen, jolle oikaisuvaatimus tehdään, on Kajaanin kaupungin
                sivistyslautakunta (varhaiskasvatuslain 50 §:ssä tarkoitettu kunnan
                monijäseninen toimielin)
              </P>
              <P>
                Kirjaamon yhteystiedot:
                <br />
                Postiosoite: PL 133, 87101 Kajaani
                <br />
                Käyntiosoite: Pohjolankatu 13, 87100 Kajaani
                <br />
                Sähköpostiosoite: kajaani@kajaani.fi
                <br />
                Puhelinnumero: 08 61551
                <br />
                  Kirjaamon aukioloaika on maanantaista torstaihin klo 8.00-16.00.
                  Perjantaisin tai juhlapyhien aattoina 9.00-15.00.
              </P>
              <H3>Oikaisuvaatimuksen muoto ja sisältö</H3>
              <P>
                Oikaisuvaatimus on tehtävä kirjallisesti. Myös sähköinen asiakirja
                täyttää vaatimuksen kirjallisesta muodosta.
              </P>
              <P noMargin>
                Oikaisuvaatimuksessa, joka on
                osoitettava Kajaanin kaupungin sivistyslautakunnalle, on ilmoitettava:
              </P>
              <ul>
                <li>päätös, johon vaaditaan oikaisua,</li>
                <li>millaista oikaisua päätökseen vaaditaan,</li>
                <li>millä perusteilla oikaisua vaaditaan</li>
              </ul>
              <P>
                Oikaisuvaatimuksessa on lisäksi ilmoitettava tekijän nimi, postiosoite,
                puhelinnumero ja muut asian hoitamiseksi tarvittavat yhteystiedot.
              </P>
              <P>
                Jos oikaisuvaatimuspäätös voidaan antaa tiedoksi sähköisenä viestinä,
                yhteystietona pyydetään ilmoittamaan myös sähköpostiosoite.
              </P>
              <P>
                Jos oikaisuvaatimuksen tekijän puhevaltaa käyttää hänen laillinen
                edustajansa tai asiamiehensä tai jos oikaisuvaatimuksen laatijana on joku
                muu henkilö, oikaisuvaatimuksessa on ilmoitettava myös tämän
                yhteystiedot.
              </P>
              <P noMargin>Oikaisuvaatimukseen on liitettävä</P>
              <ul>
                <li>
                  oikaisuvaatimuksen kohteena oleva päätös (alkuperäisenä tai
                  jäljennöksenä) oikaisuvaatimusohjeineen
                </li>
                <li>
                  selvitys siitä, milloin oikaisuvaatimuksentekijä on saanut päätöksen
                  tiedoksi, tai muu selvitys oikaisuvaatimusajan alkamisesta
                </li>
                <li>
                  asiakirjat, joihin oikaisuvaatimuksen tekijä vetoaa, jollei niitä ole jo
                  aikaisemmin toimitettu viranomaiselle.
                </li>
              </ul>
              <P>
                Asianosaiselle lähetettäviin oikaisuvaatimusohjeisiin merkitään
                lähettämispäivä:
              </P>
              <P>
                Päätös on annettu asianosaiselle tiedoksi kirjeellä, joka on lähetetty.
                Päätös on annettu asianosaiselle tiedoksi sähköisenä tiedoksiantona, joka
                on lähetetty
              </P>
            </>
          )
        },
        assistanceDecisions: {
          decision: {
            appealInstructions: (
              <>
                <H3>Oikaisuvaatimusoikeus</H3>
                <P>
                  Oikaisuvaatimuksen saa tehdä se, johon päätös on kohdistettu tai jonka
                  oikeuteen, velvollisuuteen tai etuun päätös välittömästi vaikuttaa
                  (asianosainen).
                </P>
                <H3>Oikaisuvaatimusaika</H3>
                <P>
                  Oikaisuvaatimus on tehtävä 14 päivän kuluessa päätöksen
                  tiedoksisaannista. Oikaisuvaatimus on toimitettava Pohjois-Suomen aluehallintovirastolle
                  viimeistään määräajan viimeisenä päivänä ennen aluehallintoviraston
                  aukioloajan päättymistä.
                </P>
                <H3>Tiedoksisaanti</H3>
                <P>
                  Asianosaisen katsotaan saaneen päätöksestä tiedon, jollei muuta näytetä,
                  seitsemän päivän kuluttua kirjeen lähettämisestä. Käytettäessä tavallista
                  sähköistä tiedoksiantoa katsotaan asianosaisen saaneen tiedon
                  päätöksestä kolmantena päivänä viestin lähettämisestä. Tiedoksisaantipäivää
                </P>
                <P>
                  Tiedoksisaantipäivää ei lueta oikaisuvaatimusaikaan. Jos
                  oikaisuv aatimusajan viimeinen päivä on pyhäpäivä, itsenäisyyspäivä,
                  vapunpäivä, joulu- tai juhannusaatto tai arkilauantai, saa
                  oikaisuvaatimuksen tehdä ensimmäisenä arkipäivänä sen jälkeen.
                </P>
                <H3>Oikaisuviranomainen</H3>
                <P>Oikaisuvaatimus tehdään Pohjois-Suomen aluehallintovirastolle.</P>
                <P>
                  Sähköinen oikaisuvaatimus voidaan laatia
                   <a href='https://www.suomi.fi/palvelut/verkkoasiointi/oikaisuvaatimus-
                  varhaiskasvatus-opetus-ja-koulutus-aluehallintovirasto/d7022cad-
                  9ecd-4dad-84ec-17489d260a8d'>Suomi.fi-palvelussa</a>
                  <br />
                  Kirjallinen oikaisuvaatimus toimitetaan:
                  <br />
                  Postiosoite:  PL 6, 13035 AVI
                  <br />
                  Käyntiosoite: Linnankatu 3, Oulu
                  <br />
                  Sähköpostiosoite: kirjaamo.pohjois@avi.fi
                  <br />
                  Puhelinnumero: 0295 016 000
                  <br />
                  Kirjaamon aukioloaika: klo 8.00–16.15
                </P>
                <H3>Oikaisuvaatimuksen muoto ja sisältö</H3>
                <P>
                  Oikaisuvaatimus on tehtävä kirjallisesti. Myös sähköinen asiakirja
                  täyttää vaatimuksen kirjallisesta muodosta.
                </P>
                <P noMargin>
                  Oikaisuvaatimuksessa, joka on osoitettava valitusviranomaiselle, on
                  ilmoitettava:
                </P>
                <ul>
                  <li>päätös, johon vaaditaan oikaisua</li>
                  <li>
                    miltä kohdin päätökseen haetaan oikaisua ja millaista oikaisua
                    siihen vaaditaan tehtäväksi
                  </li>
                  <li>millä perusteella oikaisua vaaditaan.</li>
                </ul>
                <P>
                  Oikaisuvaatimuksessa on ilmoitettava tekijän nimi, postiosoite,
                  puhelinnumero ja muut asian hoitamiseksi tarvittavat yhteystiedot.
                </P>
                <P>
                  Jos oikaisuvaatimusviranomaisen päätös voidaan antaa tiedoksi
                  sähköisenä viestinä, yhteystietona pyydetään ilmoittamaan myös
                  sähköpostiosoite.
                </P>
                <P>
                  Jos oikaisuvaatimuksen tekijän puhevaltaa käyttää hänen laillinen
                  edustajansa tai asiamiehensä tai jos oikaisuvaatimuksen laatijana on joku
                  muu henkilö, oikaisuvaatimuksessa on ilmoitettava myös tämän
                  yhteystiedot.
                </P>
                <P>
                  Jos oikaisuvaatimusviranomaisen päätös voidaan antaa tiedoksi
                  sähköisenä viestinä, yhteystietona pyydetään ilmoittamaan myös
                  sähköpostiosoite.
                </P>
                <P>
                  Oikaisuvaatimuksen tekijän, laillisen edustajan tai asiamiehen on
                  allekirjoitettava oikaisuvaatimus. Sähköistä asiakirjaa ei kuitenkaan tarvitse
                  täydentää allekirjoituksella, jos asiakirjassa on tiedot lähettäjästä eikä
                  asiakirjan alkuperäisyyttä tai eheyttä ole syytä epäillä.
                </P>
                <P noMargin>Oikaisuvaatimukseen on liitettävä</P>
                <ul>
                  <li>
                    päätös, johon haetaan oikaisua, alkuperäisenä tai jäljennöksenä
                    oikaisuvaatimusohjeineen
                  </li>
                  <li>
                    selvitys siitä, milloin oikaisuvaatimuksentekijä on saanyt päätöksen
                    tiedoksi, tai muu selvitys oikaisuvaatimusajan alkamisesta
                  </li>
                  <li>
                    asiakirjat, joihin oikaisuvaatimuksen tekijä vetoaa, jollei niitä ole jo
                    aikaisemmin toimitettu viranomaiselle.
                  </li>
                </ul>
                <H3>Pöytäkirja</H3>
                <P>
                  Päätöstä koskevia pöytäkirjan otteita ja liitteitä voi pyytää Kajaanin
                  kaupungin kirjaamosta.
                  <br />
                  Postiosoite: PL 133, 87101 Kajaani
                  <br />
                  Käyntiosoite: Pohjolankatu 13, Kajaani
                  <br />
                  Sähköpostiosoite: kajaani@kajaani.fi
                  <br />
                  Puhelinnumero: 08 61551 (vaihde)
                  <br />
                    Kunnan kirjaamon aukioloaika: klo 9.00–16.00; perjantaisin ja
                    juhlapyhien aattoina klo 8.00–15.00.
                </P>
              </>
            )
          },
        }
      },
      income: {
        description: (
          <>
            <P>
              Tällä sivulla voit lähettää selvitykset varhaiskasvatusmaksuun
              vaikuttavista omista ja lastesi tuloista. Voit myös tarkastella
              palauttamiasi tuloselvityksiä ja muokata tai poistaa niitä, kunnes
              viranomainen on käsitellyt tiedot. Lomakkeen käsittelyn jälkeen voit
              päivittää tulotietojasi toimittamalla uuden lomakkeen.
            </P>
            <P>
              Kunnallisen varhaiskasvatuksen asiakasmaksut määräytyvät
              prosenttiosuutena perheen bruttotuloista. Maksut vaihtelevat perheen
              koon ja tulojen sekä varhaiskasvatusajan mukaan. Maksun määräämistä
              varten tulee toimittaa perheen tulotiedot viimeistään kahden viikon
              kuluessa varhaiskasvatuksen alkamisesta. Mikäli perhe ei toimita tulotietoja,
              maksuksi määrätään enimmäismaksu.
            </P>
            <P>
              Lisätietoja maksuista:{' '}
              <a href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-asiakasmaksut/">
                Maksut varhaiskasvatuksessa
              </a>
            </P>
         </>
        ),
        formDescription: (
          <>
            <P>
              <strong>Varhaiskasvatuksen asiakasmaksu</strong> on kiinteä kuukausimaksu,
              joka määräytyy perheen tulojen ja perheenjäsenten lukumäärän mukaan.
              Maksua määrättäessä perheeksi katsotaan yhteistaloudessa avioliitossa
              tai avioliitonomaisissa olosuhteissa elävät henkilöt sekä molempien
              samassa taloudessa asuvat alaikäiset lapset.
            </P>
            <P>
            Lisätietoa asiakasmaksuista:{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-asiakasmaksut/"
              >
                tästä
              </a>
              .
            </P>
            <P>* Tähdellä merkityt tiedot ovat pakollisia</P>
          </>
        ),
        incomesRegisterConsent:
          'Hyväksyn, että tuloihini liittyviä tietoja tarkastellaan Kelasta tarvittaessa.',
        grossIncome: {
          estimatedMonthlyIncome:
            'Keskimääräiset tulot sisältäen lomarahat, €/kk. Oma arvio ei riitä, lisää todistus tuloista liitteisiin.',
          description: (
              <>
                <P noMargin>
                </P>
              </>
          ),
        },
      },
      children: {
        vasu: {
          sharingLeopsDisclaimer:
            'Esiopetuksen oppimissuunnitelma voidaan luovuttaa uudelle varhaiskasvatuksen, esiopetuksen tai perusopetuksen järjestäjälle myös ilman huoltajan lupaa, jos se on välttämätöntä lapsen varhaiskasvatuksen, esi- tai perusopetuksen järjestämiseksi (Varhaiskasvatuslaki 41§, Perusopetuslaki 41§).',
        },
      },
      accessibilityStatement: (
        <>
          <H1>Saavutettavuusseloste</H1>
          <P>
            Tämä saavutettavuusseloste koskee Kajaanin kaupungin varhaiskasvatuksen
            eVaka-verkkopalvelua osoitteessa{' '}
            <a href="https://kajaanivarhaiskasvatus.fi">kajaanivarhaiskasvatus.fi</a>.
            Kajaanin kaupunki pyrkii takaamaan verkkopalvelun saavutettavuuden,
            parantamaan käyttäjäkokemusta jatkuvasti ja soveltamaan asianmukaisia
            saavutettavuusstandardeja.
          </P>
          <P>
            Palvelun saavutettavuuden on arvioinut palvelun kehitystiimi, ja seloste
            on laadittu 12.4.2022.
          </P>
          <H2>Palvelun vaatimustenmukaisuus</H2>
          <P>
            Verkkopalvelu täyttää lain asettamat kriittiset
            saavutettavuusvaatimukset WCAG v2.1 -tason AA mukaisesti. Palvelu ei ole
            vielä kaikilta osin vaatimusten mukainen.
          </P>
          <H2>Toimet saavutettavuuden tukemiseksi</H2>
          <P>
            Verkkopalvelun saavutettavuus varmistetaan muun muassa seuraavilla
            toimenpiteillä:
          </P>
          <ul>
            <li>
              Saavutettavuus huomioidaan alusta lähtien suunnitteluvaiheessa, mm.
              valitsemalla palvelun värit ja kirjaisinten koot saavutettavasti.
            </li>
            <li>
              Palvelun elementit on määritelty semantiikaltaan johdonmukaisesti.
            </li>
            <li>Palvelua testataan jatkuvasti ruudunlukijalla.</li>
            <li>
              Erilaiset käyttäjät testaavat palvelua ja antavat saavutettavuudesta
              palautetta.
            </li>
            <li>
              Sivuston saavutettavuudesta huolehditaan jatkuvalla valvonnalla
              tekniikan tai sisällön muuttuessa.
            </li>
          </ul>
          <P>
            Tätä selostetta päivitetään sivuston muutosten ja saavutettavuuden
            tarkistusten yhteydessä.
          </P>
          <H2>Tunnetut saavutettavuusongelmat</H2>
          <P>
            Käyttäjät saattavat edelleen kohdata sivustolla joitakin ongelmia.
            Seuraavassa on kuvaus tunnetuista saavutettavuusongelmista. Jos huomaat
            sivustolla ongelman, joka ei ole luettelossa, otathan meihin yhteyttä.
          </P>
          <ul>
            <li>
              Palvelun päivämäärävalitsinta ja monivalintojen alasvetovalikkoa ei
              ole optimoitu käytettäväksi ruudunlukijalla.
            </li>
            <li>
              Palvelun yksikkökartassa ei pysty liikkumaan
              näppäimistöllä/ruudunlukijalla, mutta yksikköjä voi selata samassa
              näkymässä olevalta listalta. Palvelussa käytetty kartta on kolmannen
              osapuolen tuottama.
            </li>
          </ul>
          <H2>Kolmannet osapuolet</H2>
          <P>
            Verkkopalvelussa käytetään seuraavia kolmannen osapuolen palveluita,
            joiden saavutettavuudesta emme voi vastata.
          </P>
          <ul>
            <li>Keycloak käyttäjän tunnistautumispalvelu</li>
            <li>Suomi.fi-tunnistautuminen</li>
            <li>Leaflet-karttapalvelu</li>
          </ul>
          <H2>Vaihtoehtoiset asiointitavat</H2>
          <P>
            <ExternalLink
              href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-hallinto/"
              text="Kajaanin kaupungin varhaiskasvatuksen hallinto"
            />
          </P>
          <H2>Anna palautetta</H2>
          <P>
            Jos huomaat saavutettavuuspuutteen verkkopalvelussamme, kerro siitä
            meille. Voit antaa palautetta{' '}
            <ExternalLink
              href="https://asiointi.kajaani.fi/eFeedback/fi/Feedback/20/87"
              text="verkkolomakkeella"
            />{' '}
            tai sähköpostitse{' '}
            <a href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-hallinto/">
            Kajaanin kaupungin varhaiskasvatuksen hallinnon yhteystiedot</a>.
          </P>
          <H2>Valvontaviranomainen</H2>
          <P>
            Jos huomaat sivustolla saavutettavuusongelmia, anna ensin palautetta
            meille sivuston ylläpitäjille. Vastauksessa voi mennä 14 päivää. Jos et
            ole tyytyväinen saamaasi vastaukseen, tai et saa vastausta lainkaan
            kahden viikon aikana, voit antaa palautteen Etelä-Suomen
            aluehallintovirastoon. Etelä-Suomen aluehallintoviraston sivulla
            kerrotaan tarkasti, miten valituksen voi tehdä, ja miten asia
            käsitellään.
          </P>
          <P>
            <strong>Valvontaviranomaisen yhteystiedot </strong>
            <br />
            Etelä-Suomen aluehallintovirasto <br />
            Saavutettavuuden valvonnan yksikkö
            <br />
            <ExternalLink
              href="https://www.saavutettavuusvaatimukset.fi"
              text="www.saavutettavuusvaatimukset.fi"
            />
            <br />
            <a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a>
            <br />
            puhelinnumero vaihde 0295 016 000
            <br />
            Avoinna: ma-pe klo 8.00–16.15
          </P>
        </>
      ),
    },
    sv: {
      footer: {
        cityLabel: '© Kajana stad',
        privacyPolicyLink: (
          <a
            href="https://www.kajaani.fi/kaupunki-ja-hallinto/yhteystiedot-ja-asiointi/tietosuoja/"
            data-qa="footer-policy-link"
            style={{ color: colors.main.m2 }}
          >
            Dataskyddsbeskrivningar
          </a>
        ),
        sendFeedbackLink: (
          <a
            href="https://asiointi.kajaani.fi/eFeedback"
            data-qa="footer-feedback-link"
            style={{ color: colors.main.m2 }}
          >
            Ge feedback
          </a>
        ),
      },
      loginPage: {
        title: 'Kajana stads småbarnspedagogik',
        login: {
          infoBoxText: (
            <>
              <P>
                {/* If you are not able to log in here, see the instructions{' '}
                <a
                  href="https://www.espoo.fi/fi/palvelut/evaka"
                  target="_blank"
                  rel="noreferrer"
                >
                  eVaka | Kajana stad
                </a>
                . You can also log in with strong authentication. */}
              </P>
            </>
          ),
        },
      },
      map: {
        mainInfo:
          "I den här vyn kan du på kartan söka enheter med småbarnspedagogik och förskola.",
      },
      applications: {
        creation: {
          transferApplicationInfo: {
            DAYCARE:
              'Barnet har redan en plats i Kajana stads småbarnspedagogik. Med denna ansökan kan du ansöka om byte till en annan enhet som erbjuder småbarnspedagogik.',
          },
        },
        editor: {
          contactInfo: {
            futureAddressInfo:
              'Kajana småbarnspedagogik betraktar adressen i befolkningsdatabasen som den officiella adressen. Adressen ändras i befolkningsregistret när du gör en flyttanmälan till posten eller magistraten.',
          },
        },
      },
      children: {
        vasu: {
          sharingLeopsDisclaimer:
            'Barnets plan för lärande i förskolan kan lämnas över till den nya anordnaren av småbarnspedagogik, förskoleundervisning eller grundläggande utbildning, också utan samtycke av vårdnadshavare, ifall uppgifterna är nödvändiga för att ordna småbarnspedagogik, förskoleundervisning eller grundläggande utbildning för barnet (lag om småbarnspedagogik 41 §, lag om grundläggande utbildning 41 §).',
        },
      },
      accessibilityStatement: (
        <>
          <H1>Tillgänglighetsutlåtande</H1>
          <P>
            Detta tillgänglighetsutlåtande gäller Kajana stads webbtjänst eVaka för
            småbarnspedagogiken på adressen{' '}
            <a href="https://kajaanivarhaiskasvatus.fi">kajaanivarhaiskasvatus.fi</a>.
            Kajana stad strävar efter att säkerställa webbtjänstens tillgänglighet,
            kontinuerligt förbättra användarupplevelsen och att tillämpa lämpliga
            tillgänglighetsstandarder.
          </P>
          <P>
            Tjänstens tillgänglighet har bedömts av tjänsteutvecklingsteamet, och
            utlåtandet har utarbetats den 12 april 2022.
          </P>
          <H2>Tjänstens överensstämmelse med krav</H2>
          <P>
            Webbtjänsten uppfyller de lagstadgade kritiska tillgänglighetskraven
            enligt nivå AA i WCAG 2.1. Tjänsten uppfyller ännu inte alla krav.
          </P>
          <H2>Åtgärder för att stödja tillgängligheten</H2>
          <P>
            Webbtjänstens tillgänglighet säkerställs bland annat genom följande
            åtgärder:
          </P>
          <ul>
            <li>
              Tillgängligheten beaktas redan från början i planeringsfasen till
              exempel genom att välja färgerna och fontstorleken i tjänsten med
              tillgängligheten i åtanke.
            </li>
            <li>Elementen i tjänsten har definierats semantiskt konsekvent.</li>
            <li>Tjänsten testas ständigt med en skärmläsare.</li>
            <li>
              Olika användare testar tjänsten och ger respons på tillgängligheten.
            </li>
            <li>
              Webbplatsens tillgänglighet säkerställs genom kontinuerliga kontroller
              vid tekniska eller innehållsmässiga förändringar.
            </li>
          </ul>
          <P>
            Detta utlåtande uppdateras när webbplatsen ändras eller tillgängligheten
            justeras.
          </P>
          <H2>Kända tillgänglighetsproblem</H2>
          <P>
            Användare kan fortfarande stöta på vissa problem på webbplatsen. Nedan
            följer beskrivningar av kända tillgänglighetsproblem. Om du upptäcker
            ett problem som inte finns med på listan, vänligen kontakta oss.
          </P>
          <ul>
            <li>
              Tjänstens datum- och flervalsfält är inte optimerade för att användas
              med skärmläsare
            </li>
            <li>
              Det går inte att navigera på serviceenhetskartan med
              tangentbordet/skärmläsaren, men man kan bläddra bland enheterna på
              listan i samma vy. Kartan som används i tjänsten är framtagen av en
              tredje part.
            </li>
          </ul>
          <H2>Tredje parter</H2>
          <P>
            Webbtjänsten använder följande tredjepartstjänster, vars tillgänglighet
            vi inte är ansvariga för.
          </P>
          <ul>
            <li>Användarautentiseringstjänsten Keycloak</li>
            <li>Tjänsten suomi.fi</li>
            <li>Karttjänsten Leaflet</li>
          </ul>
          <H2>Alternativa sätt att sköta ärenden</H2>
          <P>
            <ExternalLink
              href="https://www.kajaani.fi/kaupunki-ja-hallinto/yhteystiedot-ja-asiointi/"
              text="Kajana stads servicepunkter"
            />{' '}
            hjälper till med användningen av e-tjänsterna. Rådgivarna vid
            servicepunkterna hjälper de användare, för vilka de digitala tjänsterna
            inte är tillgängliga.
          </P>
          <H2>Ge respons</H2>
          <P>
            er en tillgänglighetsbrist i vår webbtjänst, vänligen meddela oss. Du
            kan ge respons med{' '}
            <ExternalLink
              href="https://asiointi.kajaani.fi/eFeedback/fi/Feedback/20/87"
              text="webformuläret"
            />{' '}
            eller via e-post{' '}
            <a href="mailto:viestinta@kajaani.fi">viestinta@kajaani.fi</a>.
          </P>
          <H2>Tillsynsmyndighet</H2>
          <P>
            Om du upptäcker tillgänglighetsproblem på webbplatsen, ge först respons
            till oss, webbplatsens administratörer. Det kan ta upp till 14 dagar
            tills du får ett svar från oss. Om du inte är nöjd med det svar du har
            fått eller om du inte alls har fått något svar inom två veckor, kan du
            ge respons till Regionförvaltningsverket i Södra Finland. På
            regionförvaltningsverkets webbplats finns information om hur du kan
            lämna in ett klagomål samt om hur ärendet handläggs.
          </P>
          <P>
            <strong>Kontaktuppgifter till tillsynsmyndigheten</strong>
            <br />
            Regionförvaltningsverket i Södra Finland
            <br />
            Enheten för tillgänglighetstillsyn
            <br />
            <ExternalLink
              href="https://www.tillganglighetskrav.fi"
              text="www.tillganglighetskrav.fi"
            />
            <br />
            <a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a>
            <br />
            telefonnummer till växeln 0295 016 000
            <br />
            Öppet mån.– fre. kl. 8.00–16.15
          </P>
        </>
      ),
    },
    en: {
      header: {
        lang: {
          fi: 'Suomeksi',
          sv: '',
          en: 'In English'
        },
        langMobile: {
          fi: 'Suomeksi',
          sv: '',
          en: 'English'
        },
      },
      footer: {
        cityLabel: '© City of Kajaani',
        privacyPolicyLink: (
          <a
            href="https://asiointi.kajaani.fi/eFeedback/en/PrivacyPolicy"
            data-qa="footer-policy-link"
            style={{ color: colors.main.m2 }}
          >
            Privacy notices
          </a>
        ),
        sendFeedbackLink: (
          <a
            href="https://asiointi.kajaani.fi/eFeedback/en"
            data-qa="footer-feedback-link"
            style={{ color: colors.main.m2 }}
          >
            Give feedback
          </a>
        ),
      },
      loginPage: {
        title: 'City of Kajaani early childhood education',
        login: {
          infoBoxText: (
            <>
              <P>
                {/* If you are not able to log in here, see the instructions{' '}
                <a
                  href="https://www.espoo.fi/fi/palvelut/evaka"
                  target="_blank"
                  rel="noreferrer"
                >
                  eVaka | Kajaanin kaupunki
                </a>
                . You can also log in with strong authentication. */}
              </P>
            </>
          ),
        },
      },
      map: {
        mainInfo:
          "In this view, you can search the map for Kajaani's early childhood education, pre-primary education and club places.",
      },
      applications: {
        creation: {
          preschoolInfo:
            'Pre-school education ( 4h/day ) is free of charge, but for any time exceeding this, a fee is charged.',
          transferApplicationInfo: {
            DAYCARE:
              "Your child already has a place in the City of Kajaani's early childhood education. Use this application to apply for a transfer to another early childhood education unit.",
          },
          applicationInfo: (
            <P>
              As long as the application has not been accepted,
              you can make changes to the application. After that,
              changes to the application are made by contacting
              the language and culture coordinator for early childhood education and care.
            </P>
          )
        },
        editor: {
          heading: {
            info: {
              DAYCARE: (
                <>
                  <P>
                    You can apply for early childhood education all year round. The
                    application must be submitted at the latest four months before
                    the need for early childhood education begins. If you urgently
                    need early childhood education, you must apply for a place at
                    the latest two weeks before the need begins.
                  </P>
                  <P>
                    The applicant will receive a written decision on the early
                    childhood education place via the{' '}
                    <a
                      href="https://www.suomi.fi/messages"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Messages service of Suomi.fi
                    </a>{' '}
                    or by post if the applicant has not taken in use the Messages
                    service of Suomi.fi.
                  </P>
                  <P>
                    The client fees for municipal early childhood education and the
                    client charges for service voucher users are calculated as a
                    percentage of the family’s gross income. The fees vary depending
                    on the family’s size and the hours of early childhood education.
                    Service voucher units may charge an additional monthly fee,
                    information of the possible additional fee can be found from
                    units own website. Each family must provide information about
                    their gross income, no later than two weeks after their child’s
                    early childhood education has started.
                  </P>
                  <P>
                    For more information on early childhood education and care fees,
                    how to submit a performance report and the service voucher surcharge, click here:
                    <a href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-asiakasmaksut/">
                    early childhood education and care fees
                    </a>
                    .
                  </P>
                  <P fitted={true}>* Tähdellä merkityt tiedot ovat pakollisia</P>
                </>
              ),
              PRESCHOOL: (
                <>
                  <P>
                  Pre-school education is attended in the year before compulsory education. {' '}
                  Pre-school education is free of charge. For more information: {' '}
                  <a href=' https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/esiopetus/'>
                  https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/esiopetus/</a>
                  </P>
                  <P>
                    The decisions will be sent to the{' '}
                    <a
                    href="https://www.suomi.fi/messages"
                    target="_blank"
                    rel="noreferrer"
                    >
                      Suomi.fi Messages
                    </a>{' '}
                      service or by post, if the applicant does not use the{' '}
                    <a
                      href="https://www.suomi.fi/messages"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Suomi.fi Messages
                    </a>{' '}
                    service.
                  </P>
                  <P fitted={true}>* Information marked with a star is required</P>
                </>
              ),
              CLUB: (
                <>
                  <P>
                    Applications for playgroup activities are made electronically with an application form
                    for early childhood education. In the application form, you select part-day care as the
                    type of care and write in the additional information that you are applying for playgroup activities.
                  </P>
                  <P>
                    The playgroup fee is 17% of the Early Childhood Education and Care fee,
                    which is calculated from family’s income and size.
                  </P>
                  <P>
                    Playgroup activities are active from September to the end of May.
                    Kids can attend Playgroup activities from the begining of the month in which the child turns 3 years old.
                  </P>
                  <P fitted={true}>* Information marked with an asterisk is required</P>
                </>
              )
            },
          },
          actions: {
            allowOtherGuardianAccess: (
              <span>
                I understand that the application will also be visible to the other
                guardian. If the other guardian should not be able to see this
                application, please contact the Early Childhood Education Service
                Guidance, whose contact information can be found{' '}
                <a
                  href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>
                .
              </span>
            ),
          },
          serviceNeed: {
            startDate: {
              info: {
                PRESCHOOL: [
                  <>
                    <P>
                      Pre-school education is attended in the year before compulsory education starts.
                      Pre-school education is free of charge. For more information:{' '}
                      <a href='https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/esiopetus/'>https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/esiopetus/</a>

                    </P>
                  </> as unknown as string
                ],
              },
            },
            urgent: {
              attachmentsMessage: {
                text: (
                  <P fitted={true}>
                    If the need for a place in early childhood education arises from
                    sudden employment or a sudden start of studies, you will need to
                    apply for a place no later than{' '}
                    <strong>two weeks before</strong> your child needs it. In
                    addition to the application, you will need to{' '}
                    <strong>
                      provide documents as proof of the employment or student status
                    </strong>{' '}
                    of both guardians living in the same household.
                    If you cannot add attachments to the application electronically,
                    please send them by post to Kajaanin kaupunki/Varhaiskasvatus,
                    Pohjolankatu 13, 87100 Kajaani.
                  </P>
                ),
              }
            },
            dailyTime: {
              connectedDaycareInfo: (
                <>
                  <P>
                    If necessary, you can apply for additional early childhood education
                    for your child in addition to pre-school education, which is subject to a fee.
                    Additional early childhood education is provided in the mornings before pre-school
                    and after pre-school according to the opening hours of the day-care center.
                  </P>
                  <P>
                    A separate written decision will be issued on the early
                    childhood education place. The decision will be sent to the{' '}
                    <a
                      href="https://www.suomi.fi/messages"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Suomi.fi Messages
                    </a>{' '}
                    service or by post if you do not use the{' '}
                    <a
                      href="https://www.suomi.fi/messages"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Suomi.fi messages
                    </a>{' '}
                    service.
                  </P>
                </>
              ),
            },
          },
          fee: {
            links: (
              <>
              <P>
                You can find further information about early childhood education
                fees, additional fees for service voucher users and the income
                statement form here:
                <br />
                <a
                  href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-asiakasmaksut/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Early childhood education fees
                </a>
              </P>
              <P>
                <a
                  href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/yksityinen-varhaiskasvatus/varhaiskasvatuksen-palveluseteli/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Additional fees for service voucher users
                </a>
              </P>
              </>
            )
          },
          contactInfo: {
            futureAddressInfo:
              "Kajaani's early childhood education considers the address retrieved from the Population Register Centre as the official address. The address in the Population Register Centre changes when the applicant makes a notification of move to Posti or the Local Register Office.",
          },
        },
      },
      income: {
        description: (
          <>
           <P>
             On this page, you can submit statements on your and your
             children&apos;s earnings that affect the early childhood education
             fee. You can also view, edit, or delete income statements that you
             have submitted until the authority has processed the information.
             After the form has been processed, you can update your income
             information by submitting a new form.
           </P>
           <P>
             The client fees for municipal early childhood education are determined
             as a percentage of the family’s gross income. The fees vary according
             to family size, income and time in early childhood education.
             For the purposes of determining the fee,
             the family's informations of incomes must be submitted within two weeks
             of the start of the early childhood education and care.
             If the family fails to provide income information, the maximum fee will be imposed.
           </P>
           <P>
             More information on the fees:{' '}
             <a href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-asiakasmaksut/">
               Early childhood education fees
             </a>
           </P>
         </>
        ),
        formDescription: (
          <>
            <P>
              The early childhood education and care fee is a fixed monthly fee based on
              the family's income and the number of family members.
              For the purpose of determining the fee, a family is defined as persons living
              in a common household in a married or unmarried state, and minor children of
              both persons living in the same household.
            </P>
            <P>
              For more information on the fees: {' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.kajaani.fi/kasvatus-ja-koulutus/varhaiskasvatus-ja-esiopetus/varhaiskasvatuksen-asiakasmaksut/"
              >
                here
              </a>
              .
            </P>
            <P>* The information denoted with an asterisk is mandatory.</P>

          </>
        ),
        assure: <>I confirm that the information I have provided is correct. *</>,
      },
      children: {
        vasu: {
          sharingVasuDisclaimer:
            "When a child changes to another early childhood education unit in the City of Kajaani, the early childhood education plan and other early childhood education documents are transferred to the new unit (also applies to early childhood education organized by Kajaani as a purchasing service). The guardian will be informed about the transfer in advance. We ask the guardian for permission to hand over the early childhood education plan to an outsider. However, if a child enters private or other municipality's early childhood education, the early childhood education plan must be submitted to the new early childhood education provider even without permission, if the information is necessary for arranging the child's early childhood education (section 41 (3) of the Early Childhood Education Act). In addition, the early childhood education plan must be submitted to the organizer of pre-primary or primary education if it is necessary for the organization of the child's education (section 41 (4) of the Basic Education Act). The guardian will be informed of the transfer in advance.",
          sharingLeopsDisclaimer:
            "The pre-primary learning plan may be handed over to a new provider of early childhood education, pre-primary education or basic education even without the guardian's permission, if it is necessary for arranging early childhood education, pre-primary or primary education (Section 4 of the Act on Early Childhood Education and Care, Section 41 of the Basic Education Act).",
        },
      },
      accessibilityStatement: (
        <>
          <H1>Accessibility statement</H1>
          <P>
            This accessibility statement applies to the City of Kajaani's early
            childhood education online service eVaka at{' '}
            <a href="https://kajaanivarhaiskasvatus.fi">kajaanivarhaiskasvatus.fi</a>.
            The City of Kajaani endeavours to ensure the accessibility of the online
            service, continuously improve the user experience and apply appropriate
            accessibility standards.
          </P>
          <P>
            The accessibility of the service was assessed by the development team of
            the service, and this statement was drawn up on 12 April 2022.
          </P>
          <H2>Compliance of the service</H2>
          <P>
            The online service complies with the statutory critical accessibility
            requirements in accordance with Level AA of the Accessibility Guidelines
            for the WCAG v2.1. The service is not yet fully compliant with the
            requirements.
          </P>
          <H2>Measures to support accessibility</H2>
          <P>
            The accessibility of the online service is ensured, among other things,
            by the following measures:
          </P>
          <ul>
            <li>
              Accessibility has been taken into account from the beginning of the
              design phase, for example, when choosing the colours and font sizes of
              the service.
            </li>
            <li>
              The service elements have been defined in consistently in terms of
              semantics.
            </li>
            <li>The service is continuously tested with a screen reader.</li>
            <li>
              Various users test the service and give feedback on its accessibility.
            </li>
            <li>
              When website technology or content changes, its accessibility is
              ensured through constant monitoring.
            </li>
          </ul>
          <P>
            This statement will be updated in conjunction with website changes and
            accessibility evaluations.
          </P>
          <H2>Known accessibility issues</H2>
          <P>
            Users may still encounter some issues on the website. The following
            contains a description of known accessibility issues. If you notice an
            issue on the site that is not listed, please contact us.
          </P>
          <ul>
            <li>
              Date pickers and multi-select dropdowns in the service are not
              optimised to be used with a screen reader.
            </li>
            <li>
              The service's unit map cannot be navigated using the keyboard/screen
              reader, but the units can be browsed on the list available in the same
              view. The map used in the service is produced by a third party.
            </li>
          </ul>
          <H2>Third parties</H2>
          <P>
            The online service uses the following third party services, the
            accessibility of which we cannot be responsible for.
          </P>
          <ul>
            <li>Keycloak user identification service</li>
            <li>Suomi.fi identification</li>
            <li>Leaflet map service</li>
          </ul>
          <H2>Alternative ways of accessing the service</H2>
          <P>
            <ExternalLink
              href="https://www.kajaani.fi/en/city-and-administration/contact-information-and-services/"
              text="The City of Kajaani’s Service Points"
            />{' '}
            provide assistance with using electronic services. Service advisors at
            the Service Points help users who cannot access digital services.
          </P>
          <H2>Give feedback</H2>
          <P>
            If you notice an accessibility gap in our online service, please let us
            know! You can give us feedback using the{' '}
            <ExternalLink
              href="https://asiointi.kajaani.fi/eFeedback/en/Feedback/20"
              text="online form"
            />{' '}
            or by emailing{' '}
            <a href="mailto:viestinta@kajaani.fi"> viestinta@kajaani.fi</a>.
          </P>
          <H2>Supervisory authority</H2>
          <P>
            If you notice any accessibility issues on the website, please send us,
            the site administrator, feedback first. It may take us up to 14 days to
            reply. If you are not satisfied with the reply or you do not receive a
            reply within two weeks, you can give feedback to the Regional State
            Administrative Agency for Southern Finland. The website of the Regional
            State Administrative Agency for Southern Finland explains in detail how
            a complaint can be submitted, and how the matter will be processed.
          </P>
          <P>
            <strong>Contact information of the supervisory authority</strong>
            <br />
            Regional State Administrative Agency of Southern Finland
            <br />
            Accessibility Supervision Unit
            <br />
            <ExternalLink
              href="https://www.saavutettavuusvaatimukset.fi"
              text="www.saavutettavuusvaatimukset.fi"
            />
            <br />
            <a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a>
            <br />
            tel. (exchange) 0295 016 000
            <br />
            Open: Mon-Fri 8.00–16.15
          </P>
        </>
      ),
    },
  },
  cityLogo: {
    src: MunicipalityLogo,
    alt: 'Kajaani logo',
  },
  routeLinkRootUrl: 'https://reittiopas.hsl.fi/reitti/',
  mapConfig,
  featureFlags,
  getMaxPreferredUnits() {
    return 3
  },
}

export default customizations
